.wrapper {
  position: relative;
  width: 370px;
  height: 150px;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #edf2f9;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  cursor: pointer;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.input {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
