@import '../../App.scss';

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1019;
  width: 260px;
  background-color: #262a3f;
  color: $white;
}

.linkWrapper {
  padding: 24px 16px;
  display: inline-flex;
  flex-direction: column;
  max-height: 100vh;
  width: 100%;
  overflow: auto;
}

.userBlock {
  border-bottom: 1px solid #d8e2ef;
}

.navItem {
  display: block;
  margin-bottom: 4px;
}

.navLink {
  display: flex;
  align-items: center;
  color: #eaedf2;
  border: 0.0625rem solid #262b40;

  font-size: 1rem;
  vertical-align: middle;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;

  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  & svg {
    margin-right: 0.5rem;
    color: #ffffff;
  }

  &:global(.active) {
    color: #eaedf2;
    background-color: #2e3650;
    border: 0.0625rem solid #4c5680;
  }

  &:hover {
    color: #eaedf2;
    background-color: #2e3650;
  }
}

.logo {
  margin: 16px auto;
  display: block;
}
